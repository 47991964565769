import { defineComponent } from 'vue';
import { Pregame, Postgame, Stoppedgame, Testgame } from '@/components/Widgets/GameStates';
import { pickBy } from 'lodash';
import './RoomScore.scss';
import { getScoresForTeam } from '@/types/teams';
export default defineComponent({
    name: 'RoomScore',
    components: {
        Pregame,
        Testgame,
        Postgame,
        Stoppedgame,
    },
    props: {
        roomid: {
            type: String,
            required: false
        }
    },
    data: () => ({
        // These need to come from the backend
        roomnames: {
            balls: 'BirdsRoom',
            maze: 'LaserMaze',
            telefoon: 'PhoneRoom',
            reactionroom: 'ReactionRoom',
            codes: 'CodeRoom',
            cluedo: 'SearchRoom',
            birdsroom: 'BirdsRoom',
            lasermaze: 'LaserMaze',
            phoneroom: 'PhoneRoom',
            coderoom: 'CodeRoom',
            searchroom: 'SearchRoom'
        }
    }),
    computed: {
        status() {
            return this.$store.direct.state.rooms.status.score;
        },
        gamestate() {
            return this.$store.direct.state.rooms.gameState;
        },
        ingameStatus() {
            return this.$store.direct.state.rooms.ingameStatus;
        },
        level() {
            return this.$store.direct.state.rooms.level;
        },
        clockRunning() {
            return this.$store.direct.state.rooms.clockRunning;
        },
        timeLeft() {
            return this.$store.direct.state.rooms.runningTimer;
        },
        score() {
            return this.$store.direct.state.rooms.score;
        },
        gameType() {
            return this.$store.direct.state.rooms.type;
        },
        scores() {
            var _a, _b;
            if (((_b = (_a = this.status) === null || _a === void 0 ? void 0 : _a.team) === null || _b === void 0 ? void 0 : _b.games) === undefined)
                return {};
            const scores = getScoresForTeam(this.status.team);
            return pickBy(scores, (_, key) => !['score'].includes(key));
        },
        levels() {
            var _a, _b;
            if (((_b = (_a = this.status) === null || _a === void 0 ? void 0 : _a.team) === null || _b === void 0 ? void 0 : _b.games) === undefined)
                return {};
            const scores = {};
            this.status.team.games.forEach(game => {
                var _a;
                const room = game.room;
                const level = game.level;
                if ((_a = scores[room]) !== null && _a !== void 0 ? _a : 0 < level) {
                    scores[room] = level + 1;
                }
            });
            return pickBy(scores, (_, key) => !['score'].includes(key));
        },
        totalScore() {
            return Object.values(this.scores).reduce((total, score) => score + total, 0);
        }
    }
});
